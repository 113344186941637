.opening-hours {
  background-color: #f8fafc;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  .today-hours {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .status {
      font-weight: 600;
      color: #2d3748;
    }

    .hours {
      color: #4a5568;
    }

    .arrow {
      margin-left: auto;
      font-size: 0.75rem;
      color: #718096;
    }
  }

  .all-hours {
    .day-row {
      padding: 0.75rem 1rem;
      display: flex;
      justify-content: space-between;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #edf2f7;
      }

      &.current {
        background-color: #ebf8ff;
        font-weight: 500;
      }

      .day {
        color: #2d3748;
      }

      .hours {
        color: #4a5568;
      }
    }
  }
}
