.error-page-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  overflow: hidden;

  .not-found-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.8;
  }

  .error-content {
    background: rgba(255, 255, 255, 0.95);
    padding: 3rem;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;

    h1 {
      font-size: clamp(2rem, 5vw, 2.5rem);
      color: #2d3748;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }

    p {
      font-size: clamp(1rem, 3vw, 1.25rem);
      color: #4a5568;
      line-height: 1.6;
      margin-bottom: 2rem;
    }

    .home-button {
      display: inline-block;
      text-decoration: none;

      .btn {
        background-color: rgb(255, 215, 3);
        color: #2d3748;
        padding: 0.75rem 2rem;
        font-size: 1.1rem;
        font-weight: 500;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .error-page-container {
    padding: 1rem;

    .error-content {
      padding: 2rem;
    }
  }
}
