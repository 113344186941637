.home-page {
  position: relative;
  min-height: 100vh;
}

.landing-photo {
  object-fit: cover;
  width: 100%;
  height: 92%;
  position: fixed;
  z-index: -2;
  will-change: transform;

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.3s ease;
  }
}

.loading-skeleton {
  padding: 2rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
