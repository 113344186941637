$primary-color: #222222;
$secondary-color: #222222;

$background-color: #fff;
$text-color: #333;

.navbar {
  background: linear-gradient(90deg, $primary-color 0%, $secondary-color 100%);
  height: 70px;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  .logo-image {
    height: 65px;
    width: auto;
  }
}

.menu-icon {
  display: none;
  font-size: 1.8rem;
  color: $background-color;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-left: 30px;
    position: relative;
  }
}

.nav-links {
  color: $background-color;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background 0.3s ease;
  font-weight: 500;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

/* Dropdown styles */
.dropdown {
  .dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font: inherit;
    color: $background-color;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    .caret-icon {
      margin-left: 5px;
    }
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background: $primary-color;
    min-width: 180px;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition:
      opacity 0.3s ease,
      transform 0.3s ease,
      visibility 0.3s;
    z-index: 1000;

    .dropdown-item {
      display: block;
      padding: 10px 15px;
      color: $background-color;
      text-decoration: none;
      transition: background 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .dropdown-content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .dropdown-content.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@media screen and (max-width: 875px) {
  .menu-icon {
    display: block;
    z-index: 1001;
  }

  .nav-menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);
    background: linear-gradient(90deg, $primary-color 0%, $secondary-color 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    z-index: 1000;
    padding: 0;
    margin: 0;
  }

  .nav-menu.active {
    transform: translateX(0);
  }

  /* Ensure each list item occupies full width and is centered */
  .nav-menu li {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  /* Make each nav link a block element that fills its container */
  .nav-links {
    display: block;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  /* Specific adjustments for the dropdown toggle button */
  .dropdown .dropdown-toggle {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px;
    background: none;
    border: none;
    color: $background-color;
    font-size: inherit;
    cursor: pointer;
  }

  /* Position dropdown content statically for mobile */
  .dropdown .dropdown-content {
    position: static;
    opacity: 0;
    visibility: hidden;
    transform: none;
    box-shadow: none;
    background: none;
    margin-top: 10px;
    width: 100%;
  }

  .dropdown .dropdown-content.show {
    opacity: 1;
    visibility: visible;
  }
}
