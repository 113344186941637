.hero-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2));
    z-index: 1;
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 90rem;
  width: 100%;
  padding: 0 1rem;

  h1 {
    color: #fff;
    font-size: clamp(2.5rem, 5vw + 1rem, 6.25rem);
    text-align: center;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    @media (prefers-reduced-motion: no-preference) {
      transition: font-size 0.3s ease;
    }
  }
}

.button-container {
  margin-top: 1rem;
}

.cta-link {
  text-decoration: none;
  display: inline-block;

  &:focus-visible {
    outline: 3px solid #fff;
    outline-offset: 4px;
    border-radius: 4px;
  }
}

.cta-button {
  @media (hover: hover) {
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

@container (width < 768px) {
  .hero-content {
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    padding: 1rem 0.5rem;
  }
}
