.contactus-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem max(10%, 2rem);
}

.heading-contactus {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem max(10%, 2rem);

  .heading {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  .hr-about-us {
    border: none;
    height: 1px;
    background-color: #e2e8f0;
    margin: 1.5rem 0 3rem;
    width: 100%;
  }
}

.contactus-content {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 4rem;
  align-items: start;

  .left-aligned-contactus {
    h3 {
      font-size: 1.5rem;
      color: #2d3748;
      margin-bottom: 1.5rem;
    }

    .form-contactus {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input,
      textarea {
        width: 80%;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        font-size: 1rem;
        transition: border-color 0.2s ease;
        background-color: #f8fafc;

        &:focus {
          outline: none;
          border-color: #3182ce;
          background-color: white;
        }

        &::placeholder {
          color: #a0aec0;
        }
      }

      textarea {
        min-height: 150px;
        resize: vertical;
      }

      button {
        width: auto;
        min-width: 120px;
        padding: 0.75rem 1.5rem;
        background-color: rgb(255, 215, 3);
        color: #000000;
        border: none;
        border-radius: 0.5rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        align-self: center;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .contactus-captcha {
      text-align: center;
      font-size: 0.875rem;
      color: #718096;
      margin-top: 1rem;

      a {
        color: #3182ce;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .right-aligned-contactus {
    h3 {
      font-size: 1.5rem;
      color: #2d3748;
      margin-bottom: 1rem;
    }

    p {
      color: #4a5568;
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }

    .whatsapp-button {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.75rem 1.5rem;
      background-color: #25d366;
      color: white;
      border-radius: 0.5rem;
      transition: all 0.2s ease;
      margin-bottom: 2rem;

      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      }
    }

    .phone {
      color: #3182ce;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.contactus-maps-container {
  margin-top: 4rem;
  height: 500px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 1rem;
  }
}

.whatsapp-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #25d366;
  color: white;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  width: 80%;
  transition: all 0.2s ease;
  font-size: 1.1rem;

  img {
    width: 28px;
    height: 28px;
  }

  span {
    color: white;
    font-weight: 500;
  }

  &:hover {
    background-color: #22c35e;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 1024px) {
  .contactus-container {
    padding: 2rem 5%;
  }

  .contactus-content {
    gap: 3rem;
  }
}

@media (max-width: 900px) {
  .contactus-content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .contactus-container {
    padding: 1.5rem 1rem;
  }

  .contactus-maps-container {
    margin-top: 2rem;
    height: 400px;
  }
}
