* {
  margin: 0;
  padding: 0;
  font-family: 'Lusitana', Georgia, serif;
  font-size: 18px;
}

h1,
h2 {
  font-size: 25px;
}
