.footer {
  background-color: #222222;
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.footer-logo {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.75rem;
  font-weight: 600;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
}

.social-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.social-link {
  color: #ffffff;
  font-size: 1.25rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }

  &[aria-label='Facebook']:hover {
    color: #1877f2;
  }

  &[aria-label='Instagram']:hover {
    color: #e4405f;
  }

  &[aria-label='Youtube']:hover {
    color: #ff0000;
  }

  &[aria-label='Twitter']:hover {
    color: #1da1f2;
  }

  &[aria-label='LinkedIn']:hover {
    color: #0077b5;
  }
}

.footer-bottom {
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 0;
  }

  .footer-content {
    gap: 1.5rem;
  }

  .social-links {
    gap: 1rem;
  }

  .social-link {
    width: 35px;
    height: 35px;
    font-size: 1.1rem;
  }

  .footer-logo {
    font-size: 1.5rem;
  }
}
