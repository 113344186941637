.about-container {
  padding: 4rem max(10%, 2rem);
  background-color: #f8fafc;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
}

.about-header {
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: clamp(2rem, 5vw, 3rem);
    color: #2c3e50;
    margin-bottom: 1rem;
  }
}

.sections-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.about-section {
  display: grid;
  grid-template-columns: minmax(300px, 600px) minmax(300px, 600px);
  gap: 6rem;
  align-items: center;
  justify-content: center;

  &:nth-child(even) {
    direction: rtl;

    .content-wrapper {
      direction: ltr;
    }
  }

  .image-wrapper {
    position: relative;
    aspect-ratio: 4/3;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 500px;

    h2 {
      font-size: clamp(1.75rem, 3vw, 2.5rem);
      color: #2c3e50;
      font-weight: 600;
    }

    p {
      font-size: clamp(1rem, 1.5vw, 1.125rem);
      line-height: 1.6;
      color: #64748b;
    }
  }
}

@media (max-width: 700px) {
  .about-section {
    grid-template-columns: 1fr;
    gap: 2rem;

    &:nth-child(even) {
      direction: ltr;
    }

    .content-wrapper {
      text-align: center;
      margin: 0 auto;
    }
  }

  .about-header {
    margin-bottom: 3rem;
  }
}
