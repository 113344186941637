.our-environment-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem max(10%, 2rem);

  .heading {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .hr-environment {
    border: none;
    height: 1px;
    background-color: #e2e8f0;
    margin: 1.5rem 0 3rem;
    width: 100%;
  }
}

.p-our-environment {
  display: flex;
  gap: 4rem;
  align-items: flex-start;

  p {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a5568;
    max-width: 650px;

    strong {
      display: block;
      font-size: 1.5rem;
      color: #2d3748;
      margin: 2.5rem 0 1rem;
      font-weight: 600;

      &:first-of-type {
        margin-top: 2rem;
      }
    }

    ul {
      margin: 1rem 0;
      padding-left: 1.5rem;
      list-style: none;

      li {
        margin-bottom: 0.75rem;
        position: relative;

        &::before {
          content: '•';
          color: #4a5568;
          position: absolute;
          left: -1rem;
        }
      }
    }
  }

  .image-wrapper {
    flex: 1;
    max-width: 45%;

    img {
      width: 100%;
      height: auto;
      border-radius: 1.5rem;
      object-fit: cover;
    }
  }
}

@media (max-width: 1024px) {
  .our-environment-container {
    padding: 2rem 5%;
  }

  .p-our-environment {
    gap: 3rem;

    .image-wrapper {
      max-width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .our-environment-container {
    padding: 1.5rem 1rem;

    .heading {
      font-size: 2rem;
    }
  }

  .p-our-environment {
    flex-direction: column;
    gap: 2rem;

    p {
      order: 2;

      strong {
        font-size: 1.3rem;
        margin: 1.5rem 0 0.75rem;
      }

      ul {
        padding-left: 1.25rem;
      }
    }

    .image-wrapper {
      order: 1;
      max-width: 100%;
    }
  }
}
