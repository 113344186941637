.play-equipment-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem max(5%, 2rem);

  h1 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .hr-play-equipment {
    border: none;
    height: 1px;
    background-color: #e2e8f0;
    margin: 1.5rem 0 3rem;
    width: 100%;
  }
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 350px), 1fr));
  gap: 2rem;
  margin: 0 auto;
}

.facility-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    transform: translateY(-4px);
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .image-wrapper {
    position: relative;
    padding-top: 66.67%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }

    .label {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      background: #3182ce;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      z-index: 1;
    }
  }

  .content {
    padding: 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    p {
      color: #4a5568;
      line-height: 1.6;
      font-size: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .play-equipment-container {
    padding: 2rem 1rem;
  }

  .facilities-grid {
    gap: 1.5rem;
  }

  .facility-card {
    .image-wrapper .label {
      font-size: 0.75rem;
      padding: 0.4rem 0.8rem;
    }

    .content {
      padding: 1.25rem;
    }
  }
}
