.activities-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem max(10%, 2rem);

  .heading {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 1.3;
    text-align: left;
  }

  .hr-about-us {
    border: none;
    height: 1px;
    background-color: #e2e8f0;
    margin: 1.5rem 0 3rem;
    width: 100%;
  }
}

.activities-image-wrapper {
  margin-bottom: 2rem;
  border-radius: 1.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
}

.activities-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 4rem;
  align-items: start;

  .left-aligned-activities {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a5568;

    strong {
      font-size: 1.3rem;
      color: #2d3748;
      display: block;
      margin-bottom: 1.5rem;

      u {
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
      }
    }

    ul {
      margin: 0;
      padding-left: 1.5rem;
      list-style: none;

      li {
        position: relative;
        padding: 0.35rem 0;

        &::before {
          content: '•';
          position: absolute;
          left: -1.25rem;
          color: #4a5568;
        }
      }
    }
  }

  .right-aligned-activities {
    background: #f7fafc;
    padding: 2rem;
    border-radius: 1rem;

    h1 {
      font-size: 1.5rem;
      color: #2d3748;
      margin-bottom: 1rem;
      text-align: center;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.6;
      color: #4a5568;
      margin-bottom: 2rem;
    }

    .register-interest-activities {
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .activities-container {
    padding: 2rem 5%;
  }

  .activities-content {
    gap: 3rem;
  }
}

@media (max-width: 900px) {
  .activities-content {
    grid-template-columns: 1fr;
    gap: 2rem;

    .right-aligned-activities {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  .activities-container {
    padding: 1.5rem 1rem;
  }

  .activities-content {
    .right-aligned-activities {
      padding: 1.5rem;
    }
  }
}
