.policies-procedures-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem max(10%, 2rem);

  .heading {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  .hr-about-us {
    border: none;
    height: 1px;
    background-color: #e2e8f0;
    margin: 1.5rem 0 3rem;
    width: 100%;
  }
}

.p-policies-procedures {
  display: flex;
  gap: 3rem;
  align-items: flex-start;

  p {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a5568;
    max-width: 800px;

    ul {
      margin: 1rem 0;
      padding-left: 1.5rem;
      list-style: none;

      li {
        position: relative;
        padding: 0.35rem 0;
        color: #4a5568;

        &::before {
          content: '•';
          position: absolute;
          left: -1.25rem;
          color: #4a5568;
        }
      }
    }
  }

  .image-wrapper {
    flex: 1;
    max-width: 35%;

    img {
      width: 100%;
      height: auto;
      border-radius: 1.5rem;
      object-fit: cover;
    }
  }
}

@media (max-width: 1024px) {
  .policies-procedures-container {
    padding: 2rem 5%;
  }

  .p-policies-procedures {
    gap: 3rem;

    .image-wrapper {
      max-width: 40%;
    }
  }
}

@media (max-width: 768px) {
  .policies-procedures-container {
    padding: 1.5rem 1rem;

    .heading {
      font-size: 1.75rem;
    }
  }

  .p-policies-procedures {
    flex-direction: column;
    gap: 2rem;

    p {
      order: 2;

      ul {
        padding-left: 1.25rem;
      }
    }

    .image-wrapper {
      order: 1;
      max-width: 100%;
    }
  }
}
